import React, { useEffect, useState } from "react";
import { Box, SimpleGrid } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import General from "views/admin/profile/components/General";
import ProfileCard from "./component/ProfileCard";
import SubscriptionTable from "./component/SubscriptionTable";
import { Row, Col } from "antd";
import { Controller } from "Controller/Controller";
import TransactionTable from "./component/TransactionTable";
import MemberVisitTable from "./component/MemberVisitTable";
import { Button as AntdButton, Input, Modal } from "antd";
import { Alert } from "components/Alert/Alert";
import MemberTreatmentTable from "./component/MemberTreatmentTable";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const visitTableColumnsTopCreators = [
  {
    Header: "Member",
    accessor: "member",
  },
  {
    Header: "Service",
    accessor: "service_name",
  },
  {
    Header: "Count",
    accessor: "count",
  },
  {
    Header: "Date",
    accessor: "date",
  },
];
const treatmentTableColumnsTopCreators = [
  {
    Header: "Service",
    accessor: "service_name",
  },
  {
    Header: "Used",
    accessor: "used",
  },
  {
    Header: "Remaining",
    accessor: "avilable",
  },
  {
    Header: "Expiration",
    accessor: "expiration_date",
  },
];

const tableColumnsTopCreators = [
  {
    Header: " Plan",
    accessor: "plan_name",
  },
  {
    Header: "Start",
    accessor: "start_date",
  },
  {
    Header: "Expire",
    accessor: "expiration_date",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Action",
    accessor: "action",
  },
];

const transactionTableColumnsTopCreators = [
  {
    Header: "Amount",
    accessor: "amount",
  },
  {
    Header: "Date",
    accessor: "created",
  },
  {
    Header: "Currency",
    accessor: "currency",
  },
  {
    Header: "Description",
    accessor: "decription",
  },
  {
    Header: "Status",
    accessor: "status",
  },
];

const ViewSubscription = ({ row, changeCurrentStateToshowSubscription }) => {
  return (
    <p
      style={{ color: "#0981C8" }}
      onClick={() => {
        changeCurrentStateToshowSubscription(row);
      }}
    >
      View
    </p>
  );
};

const MemberDetail = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  // state
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [subscriptionRows, setSubscriptionRows] = useState([
    {
      id: "",
      plan_name: "",
      start_date: "",
      expiration_date: "",
      status: "",
    },
  ]);
  const [transactionRows, setTransactionRows] = useState([
    {
      id: "",
      amount: "",
      created: "",
      currency: "",
      decription: "",
      status: "",
    },
  ]);

  const [memberProfile, setMemberProfile] = useState({
    fullname: "",
    first_name: "",
    middle_name: null,
    last_name: "",
    address: "",
    city: "",
    state: "",
    email: null,
    phone: null,
    zip_code: null,
    profile_picture: "",
  });

  const [basicEditValues, setBasicEditValues] = useState({
    fullname: "",
    first_name: "",
    middle_name: null,
    last_name: "",
    address: "",
    city: "",
    state: "",
    email: null,
    phone: null,
    zip_code: null,
    profile_picture: "",
  });

  const [loadingCreate, setLoadingCreate] = useState(false);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [visits, setVisits] = useState([]);
  const [treatments, setTreatments] = useState([]);


  // functions

  const handleCloseEditMember = async () => {
    setOpenModalEdit(false);
    setBasicEditValues({
      fullname: "",
      first_name: "",
      middle_name: null,
      last_name: "",
      address: "",
      city: "",
      state: "",
      email: null,
      phone: null,
      zip_code: null,
      profile_picture: "",
    });
  };

  const handleChangeEdit = (e) => {
    const { name, value } = e.target;
    setBasicEditValues({
      ...basicEditValues,
      [name]: value,
    });
  };

  const handleEditMember = async () => {
    setLoadingCreate(true);
    const temp = {
      ...basicEditValues,
      id: basicEditValues.id,
      //"fullname": basicEditValues.first_name + " " + basicEditValues.last_name,
      //address1: basicEditValues.address,
      //postalCode: basicEditValues.zip_code,
    };

    delete temp.customer_plan;
    delete temp.profile_picture;
    delete temp.id;

    const url = window.location.href;
    const parts = url.split("/");
    const id = parts.pop();

    const response = await Controller.editMember(temp, id);
    if (response.status < 250) {
      Alert.openNotification(
        response.message ? response.message : "Successful",
        "success"
      );

      setMemberProfile((prevProfile) => ({
        ...prevProfile,
        ...temp,
      }));

      setOpenModalEdit(false);
      setBasicEditValues({
        fullname: "",
        first_name: "",
        middle_name: null,
        last_name: "",
        address: "",
        city: "",
        state: "",
        email: null,
        phone: null,
        zip_code: null,
        profile_picture: "",
        id: -1
      });
    } else {
      Alert.openNotification(
        response.detail[0] ? response.detail[0] : "Error",
        "error"
      );
    }
    setLoadingCreate(false);
  };



  const changeCurrentStateToshowSubscription = () => {
    console.log("hi");
  };

  
  
  const getVisitData = async () => {
    const url = window.location.href;
    const parts = url.split("/");
    const id = parts.pop();

    const response = await Controller.GetMemberVisits(id);
    if (response.status < 250) {
      var temp = [];
      for (var i in response.json.results) {
        temp.push(response.json.results[i]);
      }
      console.log(temp);
      setVisits(temp);
    }
  };

  useEffect(() => {
    getVisitData();
  }, []);

  const updateTreatmentData = () => {
    getTreatmentData();
  };
  const getTreatmentData = async () => {
    const url = window.location.href;
    const parts = url.split("/");
    const id = parts.pop();

    const response = await Controller.GetMemberTreatments(id);
    if (response.status < 250) {
      var temp = [];
      for (var i in response.json) {
        temp.push(response.json[i]);
      }
      console.log(temp);
      setTreatments(temp);
    }
  };

  useEffect(() => {
    getTreatmentData();
  }, []);

  const getSubscriptionData = async () => {
    const url = window.location.href;
    const parts = url.split("/");
    const id = parts.pop();

    const response = await Controller.GetMemberDetail(id);
    const responseSubscriptions = await Controller.GetSubscriptions(id);

    var tempSub = [];

    for (var i in responseSubscriptions.json.results) {
      var temp = {
        key: i,
        name: responseSubscriptions.json.results[i].plan_name,
        start: responseSubscriptions.json.results[i].start_date,
        exp: responseSubscriptions.json.results[i].expiration_date,
        status: responseSubscriptions.json.results[i].status,
        action: (
          <ViewSubscription
            row={responseSubscriptions.json.results[i]}
            changeCurrentStateToshowSubscription={
              changeCurrentStateToshowSubscription
            }
          />
        ),
      };
      tempSub.push(temp);
    }

    setMemberProfile(response.json);
    setSubscriptionRows(responseSubscriptions.json.results);
  };

  const getTransactionData = async () => {
    const url = window.location.href;
    const parts = url.split("/");
    const id = parts.pop();

    const responseTransactions = await Controller.GetMemberTransactions(id);

    if (
      !responseTransactions.json.results ||
      responseTransactions.json.results.length === 0
    ) {
      console.log("No transactions available.");
      setTransactionRows([]);
      return;
    }

    var tempSub = [];

    for (var i in responseTransactions.json.results) {
      var temp = {
        key: i,
        amount: responseTransactions.json.results[i].amount,
        date: responseTransactions.json.results[i].created,
        currency: responseTransactions.json.results[i].currency,
        description: responseTransactions.json.results[i].description,
        status: responseTransactions.json.results[i].status,
      };
      tempSub.push(temp);
    }

    setTransactionRows(responseTransactions.json.results);
  };

  const updateData = () => {
    getSubscriptionData();
  };
  const updateTransactionData = () => {
    getTransactionData();
  };
  const updateVisitData = () => {
    getVisitData();
  };

  useEffect(() => {
    getSubscriptionData();
    getTransactionData();
  }, []);

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid>
        <Row type="flex" justify={"space-between"} gutter={[32]}>
          <Col span={10}>
            <Row>
              <ProfileCard
                data={memberProfile}
                onEditClick={() => {
                  setBasicEditValues(memberProfile);
                  setOpenModalEdit(true);
                }}
              />
            </Row>
            <Card px="0px" mb="20px" mt="20px">
              <MemberVisitTable
                updateData={updateVisitData}
                tableData={visits}
                columnsData={visitTableColumnsTopCreators}
              />
            </Card>
            <Card px="0px" mb="20px" mt="20px">
              <MemberTreatmentTable
                updateData={updateTreatmentData}
                tableData={treatments}
                columnsData={treatmentTableColumnsTopCreators}
              />
            </Card>
          </Col>
          <Col span={14}>
            <Row>
              <Col style={{ width: "100%" }}>
                <Card>
                  <div style={{ width: "100%" }}>
                    <SubscriptionTable
                      updateData={updateData}
                      tableData={subscriptionRows}
                      columnsData={tableColumnsTopCreators}
                      handleUpdateList={getSubscriptionData}
                    />
                  </div>
                </Card>
              </Col>
              <br />
              <br />
              <Col style={{ width: "100%", marginTop: "20px" }}>
                <Card>
                  <div style={{ width: "100%" }}>
                    <TransactionTable
                      updateData={updateTransactionData}
                      tableData={transactionRows}
                      columnsData={transactionTableColumnsTopCreators}
                      handleUpdateList={getTransactionData}
                    />
                  </div>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </SimpleGrid>
      <Modal
        title={`Edit Member`}
        open={openModalEdit}
        footer={null}
        onCancel={handleCloseEditMember}
        width={500}
        style={{
          top: 20,
          marginBottom: openDatePicker ? "220px" : "",
        }}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleEditMember();
          }}
        >
          <Row type="flex" justify="space-between" gutter={[25]}>
            <Col span={windowDimensions.width < 450 ? 24 : 12}>
              <label className="formLabel">First Name</label>
              <Input
                className="inputs"
                onChange={handleChangeEdit}
                type="text"
                name="first_name"
                placeholder="Alex"
                value={basicEditValues.first_name}
                required
              />
            </Col>
            <Col span={windowDimensions.width < 450 ? 24 : 12}>
              <label className="formLabel">Last Name</label>
              <Input
                className="inputs"
                onChange={handleChangeEdit}
                type="text"
                name="last_name"
                placeholder="Doe"
                value={basicEditValues.last_name}
                required
              />
            </Col>
          </Row>
          <Row type="flex" justify="space-between" gutter={[25]}>
            <Col span={windowDimensions.width < 450 ? 24 : 12}>
              <label className="formLabel">Full Name</label>
              <Input
                className="inputs"
                onChange={handleChangeEdit}
                type="text"
                name="fullname"
                placeholder="Alex"
                value={basicEditValues.fullname}
                required
              />
            </Col>
            <Col span={windowDimensions.width < 450 ? 24 : 12}>
              <label className="formLabel">Middle Name</label>
              <Input
                className="inputs"
                onChange={handleChangeEdit}
                type="text"
                name="middle_name"
                placeholder="Doe"
                value={basicEditValues.middle_name}
                
              />
            </Col>
          </Row>

          <Row type="flex" justify="space-between" gutter={[25]}>
            <Col span={windowDimensions.width < 450 ? 24 : 12}>
              <label className="formLabel">Phone</label>
              <Input
                className="inputs"
                onChange={handleChangeEdit}
                type="text"
                name="phone"
                placeholder="Phone"
                value={basicEditValues.phone}
                required
              />
            </Col>
            <Col span={windowDimensions.width < 450 ? 24 : 12}>
              <label className="formLabel">Email</label>
              <Input
                className="inputs"
                onChange={handleChangeEdit}
                type="text"
                name="email"
                placeholder="alex.doe@gmail.com"
                value={basicEditValues.email}
                required
              />
            </Col>
          </Row>

          <Row type="flex" justify="space-between" gutter={[25]}>
            <Col span={windowDimensions.width < 450 ? 24 : 12}>
              <label className="formLabel">Address</label>
              <Input
                className="inputs"
                onChange={handleChangeEdit}
                type="text"
                name="address"
                placeholder="256 wfi street"
                value={basicEditValues.address}
                required
                minLength={10}
              />
            </Col>
            <Col span={windowDimensions.width < 450 ? 24 : 12}>
              <label className="formLabel">City</label>
              <Input
                className="inputs"
                onChange={handleChangeEdit}
                type="text"
                name="city"
                placeholder="New york"
                value={basicEditValues.city}
                required
              />
            </Col>
          </Row>

          <Row type="flex" justify="space-between" gutter={[25]}>
            <Col span={windowDimensions.width < 450 ? 24 : 12}>
              <label className="formLabel">State</label>
              <Input
                className="inputs"
                onChange={handleChangeEdit}
                type="text"
                name="state"
                placeholder="Ny"
                value={basicEditValues.state}
                required
              />
            </Col>
            <Col span={windowDimensions.width < 450 ? 24 : 12}>
              <label className="formLabel">Zip Code</label>
              <Input
                className="inputs"
                onChange={handleChangeEdit}
                type="text"
                name="zip_code"
                placeholder="12345"
                value={basicEditValues.zip_code}
                required
              />
            </Col>
          </Row>

          <div
            className="btnBox"
            style={{
              display: "flex",
              justifyContent: "end",
              marginTop: "15px",
            }}
          >
            <AntdButton
              onClick={handleCloseEditMember}
              className="white-btn create-payment-request-btn"
            >
              Close
            </AntdButton>
            <AntdButton
              htmlType="submit"
              type="primary"
              loading={loadingCreate}
              className="login-btn create-payment-request-btn"
              style={{ marginLeft: "5px", minWidth: "120px" }}
            >
              {loadingCreate ? "Editing..." : "Edit"}
            </AntdButton>
          </div>
        </form>
      </Modal>
    </Box>
  );
};

export default MemberDetail;
